import React from "react"
import { css } from "@emotion/core"
import Navegacion from "./nav"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Monarca from '../images/gatsby-icon.png'
const EnlaceHome = styled(Link)`
  color: #fff;
  text-align: center;
  text-decoration: none;
`

const Footer = () => {
    const year = new Date().getFullYear;
  return (
    <>
  
 

      <footer
        css={css`
        background-color: #e05353;
          margin-top: 5rem;
          padding: 1rem;
        `}
      >
           
          
      
        <div
          css={css`
            max-width: 1200px;
            margin: 0 auto;
            @media (min-width: 768px) {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          `}
        >
          <Navegacion />
          <EnlaceHome to="/">
          <h1>MonarcaTek</h1>
          </EnlaceHome>
        </div>
        
      </footer>
      <div css={css`
        text-align: center;
        color:  #fff;
        background-color: #25184F  ;
        margin: 0;
        font-size: 2rem;
        padding: 1rem;
        `}>
         <small>Todos los derechos Reservados {year} &copy; <br/>
         Telefono: <a href="tel:5548701797">5548701797</a>  Correo:<a href="mailto:m.adolfohuerta@gmail.com?Subject=Informacion Monarcatek">m.adolfohuerta@gmail.com</a>  <br/> 
        Ubicacion: <a href="https://www.google.com/maps/place/Soluciones+Tecnologicas+Monarca/@19.523428,-99.1671454,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xc907d6c1f2c54d32!8m2!3d19.523428!4d-99.1649567?hl=es-419">Av 1A No.20 Colonia San Jose de la Escalera 07630 Ciudad de Mexico</a> </small>
       <br/> 
       <small><a href='https://www.freepik.es/vectores/infografia'>Vector de Infografía creado por pikisuperstar - www.freepik.es</a></small>
       <small><a href="https://www.freepik.es/vectores/infografia">Vector de Infografía creado por stories - www.freepik.es</a></small>
      </div>
     
     
    </>
    
  )
}

export default Footer

import React from "react"
import Footer from "./footer"
import Helmet from 'react-helmet';
import { Global,css } from '@emotion/core';
import useSeo from '../hooks/use-seo';
import whatsapp from '../images/whatsapp.svg';
import Telefono from '../images/telefono.svg';



const Layout = (props) => {
const seo = useSeo();
const { fallbackSeo: {description,title}} = seo;

  return (
    <>
    <Global
          styles={css`
            html {
              font-size: 62.5%;
              box-sizing: border-box;
            }
            *, *:before, *:after{
              box-sizing: inherit;
            }
            body {
              font-size: 18px;
              font-size: 1.1rem;
              line-height: 1.5;
              font-family: "PT SANS", sans-serif;
            }
            h1,
            h2,
            h3 {
              margin: 0;
              line-height: 1.5;
            }
            h1,
            h2 {
              font-family: "Roboto", serif;
            }
            h3 {
              font-family: "PT SANS", sans-serif;
            }
            ul {
              list-style: none;
              margin: 0;
              padding: 0;
            }

            a{
            color: #FFFFFF;
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 1rem;
            font-family: 'PT Sans', sans-serif;
            text-decoration: none;
            padding: 1rem;
            margin-right: 1rem;
            &:last-of-type{
              margin-right:0;
            }
            &.pagina-actual{
              border-bottom: 2px solid #FFFFFF;
}
            }
          `}
        />
          <Helmet>
         
          <meta name="description" content={description} />
          <link rel="canonical" href="https://www.monarcatek.com" />
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
          <title>{title} </title>
          <meta name="robots" content="index,follow" />
          
          <meta name="robots" content="noodp" />
          <meta content="es-mx" http-equiv="content-language" />
          <link
            href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&family=Roboto:wght@400;700&display=swap"
            rel="stylesheet"
          />
           <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"></link>
          <link rel="stylesheet" href="https://cdn.snipcart.com/themes/v3.0.23/default/snipcart.css" />
          <link rel="stylesheet" href="../css/main-css.css"/>
         
         
         
        </Helmet>

       
     
     

      
    {props.children}
    <a href="https://api.whatsapp.com/send?phone=525548701797&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Monarcatek%20." 
    css={css`position:fixed;
              width:60px;
              height:60px;
              bottom:40px;
              right:40px;
              background-color:#25d366;
              color:#FFF;
              text-align:center;
              border-radius:70px;
              text-align:center;
              font-size:30px;
              box-shadow: 2px 2px 3px #999;
              z-index:100;`} target="_blank">
              <img css={css`height:100%`} src={whatsapp}></img>
    </a>
    <a href="tel:5548701797" 
    css={css`position:fixed;
              width:60px;
              height:60px;
              bottom:120px;
              right:50px;
              background-color:#e05353;
              color:#FFF;
              text-align:center;
              border-radius:70px;
              text-align:center;
              font-size:30px;
              box-shadow: 2px 2px 3px #999;
              z-index:100;`} target="_blank">
              <img css={css`height:100%`} src={Telefono}></img>
    </a>
    <Footer/>
      
  
    </>
  )
}


export default Layout
